import React, {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  Fragment,
} from "react";
import { connect } from "react-redux";
import moment from "moment";
import pointInPolygon from "point-in-polygon";
import Swal from "sweetalert2";
import { setBranchActionAsync } from "../redux/actions/actionCreators";
import { apiUrl, chiaweloCatchmentArea, statuses } from "../constants";
import { ReactComponent as WhatsappIcon } from "../assets/whatsapp-icon.svg";
import KioskPanel from "./KioskPanel";
import RegistrationPopUp from "./main/RegistrationPopup";
import TransferPopUp from "./main/TransferPopup";
import BookingTab from "./main/BookingTab";
import AddPFNForm from "./AddPFNForm";
import { get, post, isValidMobileNumber } from "../helpers";
import {
  checkIfPfnExists,
  updateNumber,
  showSetPhonePopUp,
  showCheckinPopUp,
} from "../util";

const inStore = true;

const newPfnReducer = (state, action) => {
  let current = { ...state };

  switch (action.action) {
    case "clear":
    case "reset":
      current = { tld: "+27" };
      break;

    case "name":
    case "mobile":
    case "email":
    case "pfn":
      current[action.action] = action.value;
      break;

    default:
      current = state;
  }

  return { ...current };
};

const EditPfn = ({
  pfn,
  customerId,
  customerRef,
  onClose,
  onSuccess = () => null,
}) => {
  const [newPfn, setNewPfn] = useState(null);
  const [updating, setUpdating] = useState(null);
  const name = pfn.replace(/.+\(/, "").replace(/\).*/, "");
  const updatePfn = () => {
    setUpdating(true);
    post(`${apiUrl}/chiawelo/edit-pfn`, {
      newPfn,
      customerId,
      customerRef,
      name,
    })
      .then((res) => res.json())
      .then(({ success, code, message }) => {
        setUpdating(false);
        if (success) {
          Swal.fire({
            icon: "success",
            title: "Updated!",
            position: "top",
            html: `<strong>${pfn}</strong> was changed successfully!`,
            showCloseButton: true,
          });
          onSuccess();
          onClose();
        } else {
          Swal.fire({
            icon: "warning",
            title: "Not Updated!",
            position: "top",
            text: message || code,
            showCloseButton: true,
          });
        }
      })
      .catch((e) => {
        setUpdating(false);
        Swal.fire({
          icon: "error",
          title: "Not Updated!",
          position: "top",
          text: e.message,
          showCloseButton: true,
        });
      });
  };

  return (
    <div
      id="modal-example"
      className="uk-modal uk-open"
      style={{
        display: "block",
      }}
    >
      <div className="uk-modal-dialog uk-modal-body">
        <h2 className="uk-modal-title">
          {updating ? "Changing" : "Change"} {pfn}
          {newPfn && (
            <strong style={{ color: "red" }}>
              {" "}
              to {pfn.replace(/.+\(/, newPfn + " (")}
            </strong>
          )}
        </h2>
        <AddPFNForm
          setValue={() => null}
          setPFNValue={(data, lat, lon) => {
            if (pointInPolygon([lon, lat], chiaweloCatchmentArea)) {
              setNewPfn(data.pfn);
            } else {
              Swal.fire({
                icon: "error",
                title: "Not Allowed!",
                position: "top",
                text: "The address is outside the approved catchment area for CCP",
                showCloseButton: true,
              });
            }
          }}
          mode={"edit"}
        />
        <p className="uk-text-right">
          <button
            className="uk-button uk-button-default uk-modal-close"
            type="button"
            onClick={onClose}
          >
            Cancel
          </button>{" "}
          <button
            onClick={updatePfn}
            className="uk-button uk-button-danger"
            type="button"
            disabled={updating}
          >
            {updating ? "Updating..." : "Update PFN"}
          </button>
        </p>
      </div>
    </div>
  );
};

const Main = ({
  branches,
  request,
  error,
  receivedNewTicket,
  clearNewTicketAlert,
  clearRequest,
  setBranch,
  branchId,
}) => {
  const searchPfnRef = useRef(null);
  const setUsernamePromptRef = useRef(false);
  const [bookings, setBookings] = useState([]);
  const [cancelling, setCancelling] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [disableRegistration, setDisableRegistration] = useState(true);
  const [fetchingTickets, setFetchingTickets] = useState(false);
  const [filterDate, setFilterDate] = useState("");
  const [filterPfn, setFilterPfn] = useState("");
  const [gettingTicket, setGettingTicket] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [lookupBookings, setLookupBookings] = useState([]);
  const [pfnName, setPfnName] = useState("");
  const [pfnSuggestions, setPfnSuggestions] = useState([]);
  const [registering, setRegistering] = useState(false);
  const [registerPopUpVisible, setRegisterPopUpVisible] = useState(false);
  const [searched, setSearched] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchPfn, setSearchPfn] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [registeredNames, setRegisteredNames] = useState([]);
  const [showTab, setShowTab] = useState("Welcome");
  const [transferableBooking, setTransferableBooking] = useState(null);
  const [someoneNewFormVisible, setSomeoneNewFormVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [newPfn, updateNewPfn] = useReducer(newPfnReducer, {});
  const [editablePfn, setEditablePfn] = useState(null);

  const setFilterDateCallback = (evt) => {
    const date = new Date(evt.target.value);
    const filter = evt.target.value
      ? (date.getDate() + "").padStart(2, "0") +
        "/" +
        (date.getMonth() + 1 + "").padStart(2, "0") +
        "/" +
        date.getFullYear()
      : "";
    setFilterDate(filter);
  };

  const getTickets = async () => {
    setFetchingTickets(true);
    get(`${apiUrl}/chiawelo/tickets/${branchId}/1`)
      .then((resp) => resp.json())
      .then((json) => {
        setFetchingTickets(false);
        if (json.success) {
          setBookings(json.tickets);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Problem",
            position: "top",
            text:
              "Something went wrong: " + (json.message || "Try a page refresh"),
            showCloseButton: true,
          });
        }
      })
      .catch(() => {
        setFetchingTickets(false);
      });
  };

  const changeTab = (tab, onChange = () => null) => {
    setShowTab(tab);
    if (onChange) onChange();
  };

  const searchByPFN = async () => {
    if (searchPfn) {
      setSearching(true);
      get(`${apiUrl}/chiawelo/find-pfn?pfn=${searchPfn.trim()}`)
        .then((resp) => resp.json())
        .then((pfnSuggestions) => {
          setSearching(false);
          setSearched(true);
          setPfnSuggestions(pfnSuggestions);
        })
        .catch((e) => {
          setSearching(false);
        });
    }
  };

  const clearSelectedClient = () => {
    setSearched(false);
    setSelectedClient(null);
  };

  const getJoinaQCustomerByReference = (id) => {
    if (id) {
      get(`${apiUrl}/chiawelo/customer?id=${id}`)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            setCustomer(data.customer);
            getClientTickets(data.customer._id);
          } else {
            Swal.fire({
              icon: "info",
              title: "Wait a minute",
              position: "top",
              text: data.message,
              showCloseButton: true,
            });
          }
        });
    }
  };

  const getClientTickets = (customerId) => {
    if (customerId) {
      get(`${apiUrl}/chiawelo/customer-tickets/${customerId}`)
        .then((res) => res.json())
        .then(({ success, tickets }) => {
          setLookupBookings(tickets);
        });
    }
  };

  const clearTransaction = () => {
    clearRequest();
    setCustomer(null);
    setLookupBookings([]);
    setSelectedClient(null);
    setIsMobileValid(false);
    setPfnName("");
    clearPFNSearch();
  };

  const clearPFNSearch = () => {
    if (searchPfnRef.current) searchPfnRef.current.value = "";
    setSearchPfn("");
    setPfnName("");
    setPfnSuggestions([]);
    setSearched(false);
  };

  const setPfnValue = (pfnUpdate, lat, lon) => {
    setRegisteredNames([]);
    if (pointInPolygon([lon, lat], chiaweloCatchmentArea)) {
      Object.keys(pfnUpdate).forEach((action) =>
        updateNewPfn({ action, value: pfnUpdate[action] })
      );
      checkIfPfnExists(
        pfnUpdate.pfn,
        (exists) => setDisableRegistration(exists),
        setRegisteredNames
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Not Allowed!",
        position: "top",
        text: "The address is outside the approved catchment area for CCP",
        showCloseButton: true,
      });
    }
  };

  // const checkIfDuplicate = (bookingName, bookings) => {
  //   if (bookingName) {
  //     let matched;
  //     const bookingNameFoundInTickets = bookings.find((booking) => {
  //       const match = booking.number.match(/\((?<name>.+)\)/);
  //       if (match) {
  //         if (
  //           match.groups.name.toLowerCase().trim() ===
  //           bookingName.toLowerCase().trim()
  //         ) {
  //           matched = match.groups.name.trim();
  //           return true;
  //         }
  //       }
  //     });
  //
  //     if (bookings && !bookingNameFoundInTickets) {
  //       console.log(`${bookingName} has never booked before`);
  //     }
  //   }
  // };

  const registerPFN = (onSuccess = () => null) => {
    if (!newPfn.mobile || !/^0[678][0-9]{8}$/.test(newPfn.mobile)) {
      alert("Phone number is required and must be valid.");
      return;
    }

    if (!newPfn.name) {
      alert("A first name must be provided.");
      return;
    }

    const mobile = (newPfn.mobile || "").replace(/[^0-9]/g, "");

    if (newPfn.pfn && (mobile || newPfn.noPhone)) {
      setRegistering(true);
      post(`${apiUrl}/chiawelo/register`, newPfn)
        .then((res) => res.json())
        .then(({ success, code, message }) => {
          setRegistering(false);
          if (success) {
            Swal.fire({
              icon: "success",
              title: "Registered!",
              position: "top",
              html: `<strong>${newPfn.pfn}</strong> was registered successfully!`,
              showCloseButton: true,
            });
            onSuccess();
          } else {
            Swal.fire({
              icon: "warning",
              title: "Not Registered!",
              position: "top",
              text: message || code,
              showCloseButton: true,
            });
          }
        })
        .catch((e) => {
          setRegistering(false);
          Swal.fire({
            icon: "error",
            title: "Not Registered!",
            position: "top",
            text: e.message,
            showCloseButton: true,
          });
        });
    }
  };

  const cancelBooking = (bookingId, customerId) => {
    if (!bookingId) {
      return;
    }
    Swal.fire({
      title: "Do you really want to CANCEL this booking?",
      position: "top",
      showCancelButton: true,
      confirmButtonText: "Yes, Cancel!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        setCancelling(true);
        return post(`${apiUrl}/chiawelo/cancel`, {
          bookingId,
          customerId,
        })
          .then((res) => res.json())
          .then(({ success, message, code }) => {
            setCancelling(false);
            if (success) {
              getTickets();
              Swal.fire({
                icon: "success",
                title: "Cancelled!",
                position: "top",
                html: `Cancellation completed successfully!`,
                showCloseButton: true,
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: "Not Cancelled",
                position: "top",
                text: message || code,
                showCloseButton: true,
              });
            }
          })
          .catch((e) => {
            setCancelling(false);
            Swal.fire({
              icon: "error",
              title: "Not Cancelled",
              position: "top",
              text: e.message,
              showCloseButton: true,
            });
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const reloadTV = () => {
    fetch('https://api.qmngr.com/chiawelo/reload').then()
  }

  const changeUser = () => {
    Swal.fire({
      title: `What is your name?`,
      input: "text",
      position: "top",
      inputAttributes: {
        autocapitalize: "on",
      },
      showCancelButton: true,
      confirmButtonText: "That's My Name!",
      preConfirm: (name) => {
        setUsername(name);
        localStorage.setItem("username", name);
        const hour = moment().format("H");
        let timeOfDayGreeting = "Evening";
        if (hour <= 11) {
          timeOfDayGreeting = "Morning";
        } else if (hour > 11 && hour < 17) {
          timeOfDayGreeting = "Afternoon";
        }

        Swal.fire({
          title: `Good ${timeOfDayGreeting} ${name}!!`,
          icon: "success",
          background: "white url(/confetti.png)",
          confirmButtonText: `Good ${timeOfDayGreeting} joinaQ!`,
          confirmButtonColor: "#bf263c",
        });
        changeTab("Bookings");
        return Promise.resolve({ success: true });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
        }
      }
    });
  };

  const bookingsByDate = useMemo(() => {
    const bookingData = {};
    (bookings || [])
      .filter((booking) => !!booking.customer)
      .filter(
        (booking) =>
          !["completed", "rated", "abandoned", "cancelled"].includes(
            booking.status
          )
      )
      .forEach((booking) => {
        const day = moment(booking.estimated_call_time).format(
          "dd, D MMMM"
        );

        if (
          !filterDate ||
          (filterDate &&
            moment(booking.estimated_call_time).format("DD/MM/YYYY") ===
              filterDate)
        ) {
          if (
            !filterPfn ||
            (filterPfn &&
              booking.alias && booking.alias.toLowerCase().includes(filterPfn.toLowerCase()))
          ) {
            bookingData[day] = bookingData[day] || [];
            bookingData[day].push(booking);
          }
        }
      });
    return bookingData;
  }, [bookings, filterPfn, filterDate]);

  useEffect(() => {
    if (branchId) {
      setBranch(branchId, true, !!inStore);
      getTickets();
    }
  }, []);

  useEffect(() => {
    if (receivedNewTicket) {
      getTickets();
      clearNewTicketAlert();
    }
  }, [receivedNewTicket]);

  if (!setUsernamePromptRef.current) {
    changeUser();
    setUsernamePromptRef.current = true;
    return null;
  }

  return (
    <Fragment>
      {editablePfn && (
        <EditPfn
          pfn={editablePfn.pfn}
          customerId={editablePfn.id}
          customerRef={editablePfn.ref}
          onClose={() => setEditablePfn(null)}
          onSuccess={getTickets}
        />
      )}
      {registerPopUpVisible && (
        <RegistrationPopUp
          clearPFNSearch={clearPFNSearch}
          disableRegistration={disableRegistration}
          newPfn={newPfn}
          registering={registering}
          registerPFN={registerPFN}
          registerPopUpVisible={registerPopUpVisible}
          setDisableRegistration={setDisableRegistration}
          setPfnValue={setPfnValue}
          setRegisterPopUpVisible={setRegisterPopUpVisible}
          updateNewPfn={updateNewPfn}
          cancelling={cancelling}
          registeredNames={registeredNames}
        />
      )}
      {!!transferableBooking && (
        <TransferPopUp
          booking={transferableBooking}
          close={() => setTransferableBooking(null)}
          getTickets={getTickets}
        />
      )}
      <div className="">
        {error && <div className={"uk-alert uk-alert-danger"}>{error}</div>}
        <div className="uk-margin-medium-top">
          <ul className="uk-flex-center uk-tab">
            {username && (
              <li>
                <a href=" #" style={{ color: "white" }}>
                  {username.split(" ")[0]}
                </a>
              </li>
            )}
            <li
              onClick={() =>
                changeTab("Bookings", () => {
                  getTickets();
                })
              }
              className={showTab === "Bookings" ? "uk-active" : ""}
            >
              <a href=" #">Bookings</a>
            </li>
            <li
              onClick={() => changeTab("BookingForm")}
              className={showTab === "BookingForm" ? "uk-active" : ""}
            >
              <a href=" #">New Booking</a>
            </li>
            {/*<li
              onClick={() => changeTab("Walkin")}
              className={showTab === "Walkin" ? "uk-active" : ""}
            >
              <a href=" #">New Walk-In</a>
            </li>*/}
            <li onClick={() => changeUser()}>
              <a href=" #" style={{ color: "white" }}>
                Change User
              </a>
            </li>
            <li onClick={() => reloadTV()}>
              <a href=" #" style={{ color: "white" }}>
                Refresh TV
              </a>
            </li>
          </ul>
        </div>
        <div className="uk-padding uk-padding-remove-top">
          {showTab === "Walkin" && (
            <Fragment key={"walkin-tab-view"}>
              <KioskPanel />
            </Fragment>
          )}
          {showTab === "Welcome" && (
            <Fragment key={"welcome-tab-view"}>
              {/*<div className={"uk-center uk-padding-large uk-text-center"}>
                  <div className={"uk-center uk-padding-large"}>
                    Welcome to Chiawelo's Reception
                  </div>
                  <div>Brought to you by:</div>
                  <div className={"uk-padding"}>
                    <img
                      width="220"
                      src="https://joinaq.com/assets/logo.svg"
                      alt="joinaQ&amp;trade; Personal Queuing Assistant"
                    />
                  </div>
                </div>*/}
            </Fragment>
          )}
          {showTab === "Bookings" && (
            <Fragment key={"bookings-tab-view"}>
              {fetchingTickets && (
                <div
                  style={{
                    display: "grid",
                    placeContent: "center",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                >
                  Fetching bookings...
                </div>
              )}
              {!!bookings && (
                <>
                  {/*!!!bookings ||
                      (bookings.length === 0 && (
                        <div className={"uk-text-center uk-padding-large"}>
                          No bookings found.
                        </div>
                      ))*/}
                  {
                    <div>
                      <div className={"filter-row"}>
                        <div className={"uk-row"}>
                          <input
                            autoComplete="new-password"
                            type="date"
                            placeholder={"Filter by Date"}
                            className={"uk-input"}
                            onChange={setFilterDateCallback}
                          />
                        </div>
                        <div className={"uk-row"}>
                          <input
                            autoComplete="new-password"
                            type="text"
                            placeholder={"Filter by Patient File Number (PFN)"}
                            className={"uk-input"}
                            onChange={(evt) => setFilterPfn(evt.target.value)}
                          />
                        </div>
                      </div>
                      {moment().format("H") < 11 && (
                        <div className={"uk-alert uk-alert-danger"}>
                          <strong>
                            {username ? `Hey ${username.toUpperCase()}` : "Hey"}
                            !:
                          </strong>{" "}
                          Patients who are not checked-in will not be called by
                          the TV. Please confirm the patient's phone number and{" "}
                          <u>check-in</u> every patient when they arrive.
                          <p>
                            Let's give Soweto residents an amazing Chiawelo
                            experience today
                            <span role={"img"} aria-label={"smiley"}>
                              😊
                            </span>
                            !
                          </p>
                        </div>
                      )}
                      {(Object.keys(bookingsByDate) || []).map((date) => {
                        const bookingsOnDay = bookingsByDate[date];
                        return (
                          <Fragment key={date}>
                            <Fragment key={"header-for-cluster"}>
                              <hr />
                              <div className={"vbooking-rows-header"}>
                                <strong className={"uk-h3"}>
                                  {date} ({bookingsOnDay.length} tickets)
                                </strong>
                              </div>
                              <div className={"booking-row header"}>
                                <span>PFN</span>
                                <span>Phone No.</span>
                                <span style={{ textAlign: "right" }}>
                                  Status
                                </span>
                                <span style={{ textAlign: "right" }}>
                                  Queue
                                </span>
                                <span style={{ textAlign: "right" }}>
                                  Action
                                </span>
                              </div>
                            </Fragment>
                            {bookingsOnDay.map((booking, i) => (
                              <Fragment
                                key={`bookings-tab-view-bookings-list-top-${i}${booking.alias}-${booking.estimated_call_time}`}
                              >
                                <div
                                  key={`${date}-${booking.alias}-${booking.estimated_call_time}`}
                                  className={"booking-row"}
                                >
                                  <span style={{ fontSize: "0.9em" }}>
                                    <input type="checkbox" />{" "}
                                    <span
                                      style={{
                                        fontSize: ".7em",
                                        color: "#fff",
                                        backgroundColor: "#bf263c",
                                        padding: "3px 4px",
                                        borderRadius: "2px",
                                        textDecoration: "none",
                                      }}
                                    >
                                      {moment(
                                        booking.estimated_call_time
                                      ).format("h:mmA")}
                                    </span>{" "}
                                    <a
                                      href={" #"}
                                      onClick={() =>
                                        setEditablePfn({
                                          pfn: booking.alias
                                            ? booking.alias
                                            : booking.number,
                                          ref: booking.customer.customer_id,
                                          id: booking.customer._id,
                                        })
                                      }
                                    >
                                      {booking.alias
                                        ? booking.alias
                                        : booking.number}
                                    </a>
                                    {booking.issuance_method === "whatsapp" && (
                                      <WhatsappIcon width={16} height={16} />
                                    )}
                                  </span>{" "}
                                  <span className={"phone-number-row"}>
                                    <span
                                      title={
                                        "Click to change this phone number"
                                      }
                                      className={`uk-button ${
                                        isValidMobileNumber(
                                          booking.customer.mobile
                                        )
                                          ? ""
                                          : "uk-button-danger"
                                      } uk-button-small`}
                                      onClick={() =>
                                        showSetPhonePopUp(
                                          booking.customer.customer_id,
                                          booking.customer.mobile,
                                          booking.alias || booking.number,
                                          (result) => {
                                            if (result && result.success) {
                                              Swal.fire({
                                                title: `Well Done ${
                                                  username
                                                    ? username.split(" ")[0]
                                                    : ""
                                                }!`,
                                                icon: "success",
                                                text: result.message,
                                              });
                                              getTickets();
                                            } else {
                                              Swal.fire({
                                                title: `Sorry!`,
                                                icon: "error",
                                                text: result.message,
                                              });
                                            }
                                          },
                                          (err) => {
                                            Swal.fire({
                                              title: "Uh-oh",
                                              icon: "error",
                                              text: err.message,
                                            });
                                          }
                                        )
                                      }
                                    >
                                      {!isValidMobileNumber(
                                        booking.customer.mobile
                                      ) && (
                                        <img
                                          alt={"dot icon"}
                                          title={
                                            "Please correct the phone number!"
                                          }
                                          src={"/dot.gif"}
                                          width={12}
                                        />
                                      )}{" "}
                                      {booking.customer.mobile}
                                    </span>
                                  </span>{" "}
                                  {["ticketed"].includes(booking.status) && (
                                    <>
                                      <span></span>
                                      <span></span>
                                      <div className="button-row">
                                        <button
                                          className={
                                            "uk-button uk-button-small"
                                          }
                                          onClick={() => {
                                            if (
                                              !isValidMobileNumber(
                                                booking.customer.mobile
                                              )
                                            ) {
                                              showSetPhonePopUp(
                                                booking.customer.customer_id,
                                                booking.customer.mobile,
                                                booking.alias || booking.number,
                                                (result) => {
                                                  Swal.fire({
                                                    title: `Nice one ${
                                                      username
                                                        ? username.split(" ")[0]
                                                        : ""
                                                    }!!`,
                                                    icon: "success",
                                                    text: result.message,
                                                  });
                                                  getTickets(branchId);
                                                },
                                                (err) => {
                                                  Swal.fire({
                                                    title: "Uh-oh",
                                                    icon: "error",
                                                    text: err.message,
                                                  });
                                                }
                                              );
                                              return;
                                            }
                                            showCheckinPopUp(
                                              booking.customer._id,
                                              booking._id,
                                              () => getTickets(branchId)
                                            );
                                          }}
                                        >
                                          {isValidMobileNumber(
                                            booking.customer.mobile
                                          )
                                            ? moment(
                                                booking.estimated_call_time
                                              ).diff(moment(), "hour") < 24
                                              ? "Check-in"
                                              : ""
                                            : "Fix Mobile"}
                                        </button>{" "}
                                        <button
                                          className={
                                            "uk-button uk-button-small"
                                          }
                                          onClick={() =>
                                            cancelBooking(
                                              booking._id,
                                              booking.customer._id
                                            )
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </>
                                  )}
                                  {["transferred", "queued"].includes(
                                    booking.status
                                  ) && (
                                    <>
                                      <span style={{ textAlign: "right" }}>
                                        {statuses[booking.status]}
                                      </span>
                                      <span style={{ textAlign: "right" }}>
                                        {!!booking.services_queued &&
                                          booking.services_queued[0].name.toUpperCase()}
                                      </span>
                                      <span style={{ textAlign: "right" }}>
                                        <button
                                          className={
                                            "uk-button uk-button-small"
                                          }
                                          onClick={() =>
                                            setTransferableBooking(booking)
                                          }
                                        >
                                          Transfer
                                        </button>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </Fragment>
                            ))}
                          </Fragment>
                        );
                      })}
                    </div>
                  }
                </>
              )}
            </Fragment>
          )}
          {showTab === "BookingForm" && (
            <BookingTab
              branches={branches}
              setGettingTicket={setGettingTicket}
              setSearchPfn={setSearchPfn}
              branchId={branchId}
              selectedClient={selectedClient}
              request={request}
              searchByPFN={searchByPFN}
              searching={searching}
              searched={searched}
              pfnSuggestions={pfnSuggestions}
              searchPfnRef={searchPfnRef}
              clearPFNSearch={clearPFNSearch}
              setRegisterPopUpVisible={setRegisterPopUpVisible}
              clearSelectedClient={clearSelectedClient}
              customer={customer}
              lookupBookings={lookupBookings}
              setPfnName={setPfnName}
              setSelectedClient={setSelectedClient}
              getJoinaQCustomerByReference={getJoinaQCustomerByReference}
              someoneNewFormVisible={someoneNewFormVisible}
              setSomeoneNewFormVisible={setSomeoneNewFormVisible}
              isMobileValid={isMobileValid}
              updateNumber={updateNumber}
              setIsMobileValid={setIsMobileValid}
              bookings={bookings}
              gettingTicket={gettingTicket}
              pfnName={pfnName}
              clearTransaction={clearTransaction}
              getTickets={getTickets}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = function ({
  branches,
  request,
  error,
  receivedNewTicket,
}) {
  return {
    branches,
    request,
    error,
    receivedNewTicket,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    joinQ: () => dispatch({ type: "JOIN" }),
    clearRequest: () => dispatch({ type: "CLEAR_REQUEST" }),
    clearNewTicketAlert: () => dispatch({ type: "CLEAR_TICKET_ALERT" }),
    setBranch: (branchId, sticky, inStore) =>
      dispatch(setBranchActionAsync(branchId, sticky, inStore)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
